<template>
  <!--
  <Saisirdate label="Du" v-model="jour"></Saisirdate>
  -->
  <v-text-field dense v-bind:value="datefr" v-on:input="onDate($event)" :error="erreur" v-bind="$attrs" readonly>
    <template v-slot:append>
      <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="none" offset-y :max-width="width" :min-width="width">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on"
            ><v-icon>{{ icon }}</v-icon></v-btn
          >
        </template>
        <v-date-picker v-model="sdate" show-adjacent-months :locale="locale" first-day-of-week="1" @input="onPicker"> </v-date-picker>
      </v-menu>
    </template>
  </v-text-field>
</template>
<script>
import store from "@/store"
import Vue from "vue"

export default Vue.extend({
  name: "SaisirDate",
  data: () => ({
    unsubscribe: "",
    menu: false,
    sdate: "",
    datefr: "",
    locale: "de",
    erreur: false
  }),
  created() {
    this.sdate = this.value
    if (this.value) {
      const [year, month, day] = this.value.split("-")
      if (year && month && day) {
        if (store.state.langue == "FR") {
          this.datefr = `${day}/${month}/${year}`
          this.locale = "fr"
        } else {
          this.datefr = `${day}.${month}.${year}`
          this.locale = "de"
        }
      }
    } else {
      this.datefr = ""
    }
    this.unsubscribe = this.$store.subscribe(mutation => {
      switch (mutation.type) {
        case "majLangue":
          this.toFrench()
          break
      }
    })
  },
  watch: {
    value() {
      this.sdate = this.value
      this.toFrench()
    }
  },
  props: {
    value: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 290
    },
    icon: {
      type: String,
      default: "mdi-calendar"
    }
  },
  methods: {
    toFrench() {
      if (this.sdate == null) {
        return ""
      }
      const [year, month, day] = this.sdate.split("-")
      if (year && month && day) {
        if (store.state.langue == "FR") {
          this.datefr = `${day}/${month}/${year}`
          this.locale = "fr"
        } else {
          this.datefr = `${day}.${month}.${year}`
          this.locale = "de"
        }
      }
    },
    onDate(dte) {
      if (!dte) return
      const [day, month, year] = dte.split("/")
      if (year && month && day) {
        const s = `${year}-${month}-${day}`
        const d = Date.parse(s)
        if (!isNaN(d)) {
          this.sdate = s
          this.datefr = dte
          this.$emit("input", this.sdate)
          this.erreur = false
        } else {
          this.erreur = true
        }
      }
    },
    onPicker() {
      this.menu = false
      this.toFrench()
      this.$emit("input", this.sdate)
      this.$emit("change", this.sdate)
    }
  },
  beforeDestroy() {
    this.unsubscribe = ""
  }
})
</script>
